import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Modal, Message } from "semantic-ui-react";
import logo from "../../../logo.png";
import { AppContext } from "../../../Context/AppContext";
import MDL_FORGOT_PIN_CUSTOMER from "./mdl-forgot-pin-customer/mdl-forgot-pin-customer";
import { loginBuilding, loginMasterPinBuildings } from "../../../constants";
import { useToasts } from "react-toast-notifications";

const Login = (appProps) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo, bInfo, selectedBuilding } = useContext(AppContext);
  const [, setUser] = userInfo;
  const [, setSelectedBuilding] = selectedBuilding;
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const homescreen = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [error_message, setErrorMessage] = useState();
  const [building_info, setBuildingInfo] = bInfo;
  const [mdl_forgot_pin_open, set_mdl_fotgot_pin_open] = useState(false);

  /* Async functions
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    if (building_info) {
      setBuildingInfo(building_info);
    }
  }, []);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  function onOpen_mdl_forgot_pin_customer() {
    set_mdl_fotgot_pin_open(true);
  }

  const onChangeInput = (e) => {
    setIsError(false);
    setPin(e.target.value);
  };
  const onChangeEmail = (e) => {
    setIsError(false);
    setEmail(e.target.value);
  };

  function validate() {
    var isValid = true;
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (pin?.length < 6) {
      console.log('isValid',isValid)
      isValid = false;
      setIsError(true);
      setErrorMessage("Please enter valid pin");
    } 
    
    return isValid;
  }

  const onSubmit = async () => {
    if (validate()) {
      setLoading(true);
      if (pin?.length === 6) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: pin }),
        };
        await fetch(loginBuilding, requestOptions)
          .then((response) => response.json())
          .then(async (res) => {
            if (res.code === 200) {
              const buildings = res.buildings;
              setLoading(false);
              if (Array.isArray(buildings) && buildings?.length > 0) {
                setUser({
                  role: "customer",
                  uuid: buildings[0].value,
                  firstName: buildings[0].text,
                });
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    role: "customer",
                    uuid: buildings[0].value,
                    firstName: buildings[0].text,
                  })
                );
                localStorage.setItem("building", JSON.stringify(buildings));
                setSelectedBuilding(buildings[0]);
                setBuildingInfo(buildings);
                const from = homescreen.location;
                if (from.pathname.startsWith('/building-orders') || from.pathname.includes('automatic-followup-order-request')){
                  homescreen.replace(homescreen);
                }else{
                  homescreen.replace(`/home`);
                }
              }
            } else {
              setLoading(false);
              addToast(res.success, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
            addToast("There are some error. Try again.", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } else if (pin?.length === 7) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: pin.trim(),
            email: email.trim(),
            is_role: "customer",
          }),
        };
        await fetch(loginMasterPinBuildings, requestOptions)
          .then((response) => response.json())
          .then(async (user_res) => {
            if (user_res.code === 200) {
              setLoading(false);
              const { user_id, user_name, buildings } = user_res.data;
              if (Array.isArray(buildings) && buildings?.length > 0) {
                var temp_buildings = [];
                buildings &&
                  buildings.map((val, index) => {
                    temp_buildings = [
                      ...temp_buildings,
                      { ...val, key: val.value },
                    ];
                  });
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    role: "customer",
                    uuid: pin.trim(),
                    firstName: user_name,
                  })
                );
                localStorage.setItem("building", JSON.stringify(buildings));
                setUser({
                  role: "customer",
                  uuid: pin.trim(),
                  firstName: user_name,
                });
                const from = homescreen.location;
                if (from.pathname.startsWith('/building-orders') || from.pathname.includes('automatic-followup-order-request')){
                  homescreen.replace(homescreen);
                }else{
                  homescreen.replace(`/home`);
                }
              }
            } else {
              setLoading(false);
              addToast(user_res.success, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
            addToast("There are some error. Try again.", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    }
  };


  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <div className="login-wrapper">
        <div className="login-card">
          <div className="login-card-header">
            <img src={logo} alt="PestPac API" />
          </div>
          <div className="loing-card-body">
            <Form
              className="login-form"
              error={isError}
              onSubmit={() => onSubmit()}
            >
              <Form.Group>
                <Form.Field
                  id="password"
                  name="password"
                  label="Pin #"
                  control={Input}
                  type="password"
                  maxLength="7"
                  onChange={onChangeInput}
                  placeholder="Enter your 6 OR 7 digit PIN #"
                />
              </Form.Group>
              {/* {pin.length > 6 && <Form.Group>

                             <Form.Field
                                id = 'email'
                                name = 'email'
                                label = 'Customer Email'
                                control = {Input}
                                type="email"
                                onChange = {onChangeEmail}
                                placeholder = 'Enter your email address'
                            />
                        </Form.Group> } */}
              <Message error header="Error" content={error_message} />
              <Button
                className="button-form-submit"
                color="red"
                type="submit"
                disabled={isLoading}
              >
                Submit
              </Button>
            </Form>
          </div>
          <div className="loing-card-footer">
            <div className="loing-card-footer-text">
              <text
                className="forgot-pin-link"
                onClick={() => onOpen_mdl_forgot_pin_customer()}
              >
                Forgot pin
              </text>
              If you are experiencing technical difficulties, please contact{" "}
              <span>service@homepropestcontrol.ca</span>
            </div>
          </div>
        </div>
      </div>

      {/***** MODAL: FORGOT PIN - CUSTOMER **************************************************************************/}
      <Modal
        id="mdl-forgot-pin-customer"
        dimmer={"inverted"}
        open={mdl_forgot_pin_open}
        onClose={() => set_mdl_fotgot_pin_open(false)}
      >
        <MDL_FORGOT_PIN_CUSTOMER
          set_mdl_open={set_mdl_fotgot_pin_open}
        ></MDL_FORGOT_PIN_CUSTOMER>
      </Modal>

      {/***** END MODAL: FORGOT PIN - CUSTOMER **************************************************************************/}
    </>
  );
};
export default Login;

import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Label,
  Form,
  Search,
  Modal,
  Checkbox,
  ListIcon,
} from "semantic-ui-react";
import Statistics from "../../../components/cmp-statistics/Statistics";
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import {
  acceptOrRejectOrder,
  buildingStat,
  deleteOrder,
  getAllOrdersOfUser,
  getSingleBuilding,
  viewPdfURL,
} from "../../../constants";
// import OutlinedInput from '@mui/material/OutlinedInput';
import AppPagination from "../../../components/cmp-pagination/Pagination";
import dateFormat from "dateformat";
import { isValid } from "date-fns";
import { useToasts } from "react-toast-notifications";
//import PDFViewer from './pdfViewer/PDFViewer';
import { Dropdown, Icon } from "semantic-ui-react";
import CMP_PDF_VIEWER from "../../../components/cmp-pdf-viewer/cmp-pdf-viewer";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_REPORT_UNITS from "./mdl_report_units/mdl-report-units";
import moment from "moment";

const Home = () => {
  /* VARIABLE DECLARATIONS
    ---------------------------------------------------------------------------------*/
  const { bInfo, selectedBuilding, buildingUnits, sbuildingData, userInfo, selected_units } =
    useContext(AppContext);
  const [, set_add_unit] = selected_units;
  const [, setSingleBuildingData] = sbuildingData;
  const [building_units, setBuildingUnits] = buildingUnits;
  const [open_pdf_viewer, set_open_pdf_viewer] = useState(false);
  const [user, setUser] = userInfo;
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const { addToast } = useToasts();
  const [orders, setOrders] = useState([]);
  const [checked, setChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [building_info, setBuildingInfo] = bInfo;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [sortBy, setSortBy] = useState(3);
  const [totalOrders, setTotalOrders] = useState(0);
  const [selected_order, set_selected_order] = useState();
  const [pdf_url, set_pdf_url] = useState();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [report_units, set_report_units] = useState(false);
  const [current_sort, set_current_sorted] = useState({
    sort_by: "order_number",
    sort_by_value: "DESC",
  });
  const [current_sort_order, set_current_sorted_order] = useState("ASC");
  const [mdl_report_units, set_mdl_report_units] = useState(false);
  /* EVENT LISTENERS
    ---------------------------------------------------------------------------------*/
  useEffect(() => {
    prepareBuildingInfo();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      // Send Axios request here
      // getListOfCustomers();
      if (selected_building) {
        set_mdl_progress(true);
        await getBuildingOrders(1, limit);
        set_mdl_progress(false);
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (pdf_url) {
      set_open_pdf_viewer(true);
    }
  }, [pdf_url]);

  useEffect(() => {
    if (selected_building && page > 0) {
      getBuildingOrders(page, limit);
      getBuildingUnits();
    }
  }, [limit, page, selected_building, current_sort]);

  useEffect(() => {
    if (selected_building) {
      setPage(1);
      getBuildingOrders(1, limit);
      getBuildingUnits();
    }
  }, [sortBy]);

  /* ASYNC FUNCTIONS
    ---------------------------------------------------------------------------------*/

  async function prepareBuildingInfo() {
    var buildings = [];
    if (localStorage.getItem("building")) {
      buildings = await JSON.parse(localStorage.getItem("building"));
      setBuildingInfo(buildings);
      if (!selected_building)
        setSelectedBuilding(buildings && buildings.length > 0 && buildings[0]);
    }

    if (localStorage.getItem("user")) {
      const user = await JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }

  function onChangeSortBy(event, { value }) {
    setSortBy(value);
    setSearchQuery("");
    // getBuildingOrders(1,limit,value)
  }

  function onChangeBuilding(event, { value }) {
    setSelectedBuilding(building_info.filter((f_v) => f_v.value === value)[0]);
    // getBuildingUnits();
    //getBuildingOrders(1,limit,value)
  }

  async function acceptAndRejectRequest(request, is_accepted) {
    // history.push({pathname:"/complete-order",state:{id}})
    var temp = { ...request };
    temp.building_id = selected_building?.value;
    temp.id = request.order_id;
    temp = { ...temp, is_accepted };
    temp.user_id = user.user_id;
    set_mdl_progress(true);
    const order_options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify(temp),
    };
    await fetch(acceptOrRejectOrder, order_options)
      .then((res) => res.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          localStorage.removeItem("building");
          setUser(null);
          setBuildingInfo(null);
        } else {
          if (res.code === 200) {
            getBuildingOrders(page, limit);
            addToast(res.message, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
    set_mdl_progress(false);
  }

  function goViewDetail(request) {
    history.push({ pathname: "/order-detail", state: { id: request.id } });
    // history.push({pathname:"/complete-order",state:{id}})
  }

  async function getBuildingUnits() {
    await fetch(getSingleBuilding + selected_building?.value)
      .then((response) => response.json())
      .then((response) => {
        const final_data = response.data[0];
        setSingleBuildingData(final_data)
        setBuildingUnits(final_data.units ? final_data.units : []);
      });
  }

  async function getBuildingOrders(page_no, limit_range) {
    set_mdl_progress(true);
    await fetch(
      getAllOrdersOfUser +
        `?search=${searchQuery}&order_by=${
          current_sort?.sort_by || ""
        }&order_by_value=${current_sort?.sort_by_value || ""}&page=${
          page_no ? page_no : page
        }&sort_by=${parseInt(sortBy)}&limit=${
          limit_range
            ? limit_range === "all"
              ? 99999
              : limit_range
            : limit === "all"
            ? 99999
            : limit
        }&is_admin=false&id=` +
        selected_building?.value
    )
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        var ordersFetch = [];
        if (res.statusCode === 200) {
          ordersFetch = res.body.data;
          setTotalOrders(res.body.total_count);
          // if(res.body && res.body.data && res.body.data.length === 0){
          //     //setPage(page-1)
          // }
        } else {
          ordersFetch = [];
        }
        if (page_no && page_no > 1) ordersFetch = [...orders, ...ordersFetch];
        setOrders(ordersFetch);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.error("Error:", error);
      });
  }

  function handleClick() {
    set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
    history.push(`/order-request/${selected_building?.location_id}`);
  }
  function handleChecked() {
    setChecked(!checked);
  }

  function reportUnits() {
    set_report_units(true);
  }
  function goViewDetail(id, unit_number) {
    history.push({ pathname: "/order-detail", state: { id, unit_number } });
  }

  function deleteOrderApiCall(
    unit_uuid,
    order_number,
    scheduler_email,
    building_name
  ) {
    var params = {};
    params = { ...params, order_unit_uuid: unit_uuid };
    params = { ...params, scheduler_email: scheduler_email };
    params = { ...params, order_number: order_number };
    params = { ...params, building_name: building_name };
    const requestOptionsOrder = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    set_mdl_progress(true);
    fetch(deleteOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          getBuildingOrders(1, 50, sortBy);
          setSearchResult([]);
        }
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "error",
          autoDismiss: true,
        });
        set_mdl_progress(false);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.log("Error:::::", error);
      });
  }
  function onSubmitReportUnits() {
    set_mdl_report_units(true);
  }

  function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setSearchQuery(value);
  }

  function goForEditDraft(id) {
    history.push(`automatic-followup-order-request/${id}`);
  }

  /* RENDER APP
    ---------------------------------------------------------------------------------*/

  return (
    <div className="app-content">
      <div className="app-page-header">
        <div className="app-page-title">
          {building_info?.length > 0 && (
            <Dropdown
              search
              selection
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              // input={<OutlinedInput label="Tag" />}
              placeholder="Select Building"
              label="Building: "
              onChange={onChangeBuilding}
              value={selected_building?.value}
              options={building_info}
            />
          )}
          {selected_building &&
            selected_building?.length === 1 &&
            selected_building.text}
        </div>
      </div>

      <div className="app-content-action app-content-action-search">
        {sortBy != 2 && (
          <div className="app-content-search">
            <Search
              placeholder="Search Unit Number"
              onSearchChange={(value) => doSearch(value)}
              showNoResults={false}
              value={searchQuery}
            />
          </div>
        )}
        <div className="app-content-action-right">
          <Button
            color="orange"
            onClick={() => {
              history.push(`/building-heatmap/${selected_building.value}`);
            }}
          >
            <img src="/heat-map.png" alt="icon" />
            Building Heatmap
          </Button>

          <Button
            color="blue"
            onClick={() => {
              history.push(`/building-history/${selected_building.value}`);
            }}
          >
            <img src="/history.png" alt="icon" />
            Building History
          </Button>
          {/* <Button color="blue" onClick={() => reportUnits()}>
            <img src="/request-order.svg" alt="icon" />
            Report Units
          </Button> */}
          <Button color="red" onClick={() => handleClick()}>
            <img src="/request-order.svg" alt="icon" />
            Request Service
          </Button>
        </div>
      </div>
      {!report_units ? (
        <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">
              {searchResult && searchResult.length > 0
                ? "Orders Found (" + searchResult.length + ")"
                : "Orders List (" + orders.length + ")"}
            </div>
            <Form.Select
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              placeholder="Sort By"
              label="Status: "
              onChange={onChangeSortBy}
              value={sortBy}
              options={[
                { key: 3, text: "All Orders", value: 3 },
                { key: 0, text: "In Progress / Scheduled", value: 0 },
                { key: 1, text: "Completed", value: 1 },
                { key: 2, text: "Draft", value: 2 },
              ]}
            />
          </div>

          <div className="app-table-wrapper">
            <Table sortable unstackable>
              <Table.Header className="main-home-table">
                {sortBy != 2 ? (
                  <Table.Row>
                    <Table.HeaderCell
                      // icon={<Icon name="home" size="large" />}
                      sorted={
                        current_sort && current_sort.sort_by === "order_number"
                          ? current_sort.sort_by_value === "ASC"
                            ? "ascending"
                            : "descending"
                          : null
                      }
                      onClick={() => {
                        if (current_sort?.sort_by_value === "ASC") {
                          set_current_sorted({
                            sort_by: "order_number",
                            sort_by_value: "DESC",
                          });
                        } else {
                          set_current_sorted({
                            sort_by: "order_number",
                            sort_by_value: "ASC",
                          });
                        }
                      }}
                      title="Order #"
                    >
                      Order #
                      {current_sort?.sort_by !== "order_number" && (
                        <Icon size="20" name="sort" color="black" />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Unit #">Unit #</Table.HeaderCell>
                    <Table.HeaderCell title="Location name">
                      Location
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Pest">Pest</Table.HeaderCell>
                    <Table.HeaderCell title="Treatment">
                      Treatment
                    </Table.HeaderCell>

                    <Table.HeaderCell title="Current order status">
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        current_sort && current_sort.sort_by === "work_date"
                          ? current_sort.sort_by_value === "ASC"
                            ? "ascending"
                            : "descending"
                          : null
                      }
                      onClick={() => {
                        if (current_sort?.sort_by_value === "ASC") {
                          set_current_sorted({
                            sort_by: "work_date",
                            sort_by_value: "DESC",
                          });
                        } else {
                          set_current_sorted({
                            sort_by: "work_date",
                            sort_by_value: "ASC",
                          });
                        }
                      }}
                      // icon={<Icon name="home" size="large" />}
                      title="Scheduled date"
                    >
                      {sortBy ? "Work" : "Schedule"} Date
                      {current_sort?.sort_by !== "work_date" && (
                        <Icon size="20" name="sort" color="black" />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Order scheduler">
                      Time Window
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                ) : (
                  <Table.Row>
                    <Table.HeaderCell title="No. of units">
                      Units
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Scheduler Name">
                      Scheduler
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Scheduler Email">
                      Email
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Date of request">
                      Requested Date
                    </Table.HeaderCell>
                    <Table.HeaderCell title="Current request Status">
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                )}
              </Table.Header>
              <Table.Body>
                {sortBy != 2 &&
                  searchResult.length === 0 &&
                  orders &&
                  orders.map((order, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{order.order_number}</Table.Cell>
                      <Table.Cell>{order.unit_number}</Table.Cell>
                      <Table.Cell>{order.building_name}</Table.Cell>
                      <Table.Cell>{order.pest_name || "N/A"}</Table.Cell>
                      <Table.Cell>{order.treatment_name || "N/A"}</Table.Cell>
                      <Table.Cell className="td-status">
                        {order.order_status === "completed" && (
                          <div style={{ color: "#05881F" }}>Completed</div>
                        )}
                        {order.order_status === "scheduled" && (
                          <div style={{ color: "#2196f3" }}>Scheduled</div>
                        )}
                        {order.order_status === "in progress" && (
                          <div style={{ color: "#FA8C16" }}>In Progress</div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {order.schedule_date &&
                        isValid(new Date(order.schedule_date))
                          ? moment(order.schedule_date).format("l")
                          : "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                        {order.time_range ? order.time_range : "N/A"}
                      </Table.Cell>

                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        {order.order_status != "completed" ? (
                          <>
                            <Label
                              color="red"
                              onClick={() =>
                                deleteOrderApiCall(
                                  order.unit_uuid,
                                  order.order_number,
                                  order.scheduler_email,
                                  order.building_name
                                )
                              }
                            >
                              Delete
                            </Label>
                            <Label
                              color="blue"
                              onClick={() =>
                                goViewDetail(order.order_id, order.unit_number)
                              }
                            >
                              View
                            </Label>
                          </>
                        ) : (
                          <Label
                            color="blue"
                            onClick={() => {
                              set_selected_order(order.order_number);
                              set_pdf_url(viewPdfURL + order.order_number);
                            }}
                          >
                            View Report
                          </Label>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {sortBy == 2 &&
                  searchResult.length == 0 &&
                  orders.map((order, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{order?.units?.length}</Table.Cell>
                      <Table.Cell>{order.scheduler_first_name}</Table.Cell>
                      <Table.Cell>{order.scheduler_email}</Table.Cell>
                      <Table.Cell>{order.date}</Table.Cell>
                      <Table.Cell className="td-status">
                        {order.order_status === "draft" && (
                          <div style={{ color: "#FA8C16" }}>Draft</div>
                        )}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => goViewDetail(order.order_id, null)}
                        >
                          View
                        </Label>
                        <Label
                          color="green"
                          onClick={() => goForEditDraft(order?.order_id)}
                        >
                          Edit
                        </Label>
                        <Label
                          color="red"
                          onClick={() => acceptAndRejectRequest(order, false)}
                        >
                          Reject
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {orders.length === 0 && (
              <div className="nodata-placeholder">
                There is no order to display.
              </div>
            )}
          </div>
          <div>
            <AppPagination
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              rowsTotal={orders.length}
              totalCount={totalOrders}
              showAll={true}
            />
          </div>
          {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
          <Modal
            id="mdl-pdf-viewer"
            dimmer={"inverted"}
            open={open_pdf_viewer}
            onClose={() => {
              set_open_pdf_viewer(false);
              set_pdf_url(null);
            }}
          >
            <CMP_PDF_VIEWER
              set_mdl_open={set_open_pdf_viewer}
              pdf_url={pdf_url}
              set_pdf_url={set_pdf_url}
              var_modaltitle={"Order #" + selected_order}
            />
          </Modal>

          {/***** MODAL: PROGRESS **************************************************************************/}
          {/* <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Please wait..."}
            ></MDL_PROGRESS>
          </Modal> */}

          {/***** END MODAL: PROGRESS **************************************************************************/}
        </div>
      ) : (
        <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">
              {searchResult && searchResult.length > 0
                ? "Orders Found (" + searchResult.length + ")"
                : "Units List (" + building_units.length + ")"}
            </div>
            <Button
              onClick={() => onSubmitReportUnits()}
              color="red"
              disabled={!checked}
            >
              Report
            </Button>
          </div>

          <div className="app-table-wrapper">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell title="Order #">Ref #</Table.HeaderCell> */}
                  <Table.HeaderCell title="Order #"></Table.HeaderCell>
                  <Table.HeaderCell title="Unit #">Unit #</Table.HeaderCell>
                  <Table.HeaderCell title="Bedroom Count">
                    Bedroom Count
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Warranty Pest">
                    Warranty Pest
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="Warranty Service">
                    Warranty Service
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="Expiry Date">
                    Expiry Date
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {building_units?.length > 0 &&
                  building_units?.map((units, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <Checkbox onChange={handleChecked} />
                      </Table.Cell>
                      <Table.Cell>{units.unit}</Table.Cell>
                      <Table.Cell>{units.bedroom_count}</Table.Cell>
                      <Table.Cell>
                        {units.warranty_pest ? units.warranty_pest : "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                        {units.warranty_service
                          ? units.warranty_service
                          : "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                        {units.expire_date
                          ? dateFormat(
                              new Date(units.expire_date),
                              "mm-dd-yyyy"
                            )
                          : "N/A"}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>

            {searchResult.length === 0 && orders.length === 0 && (
              <div className="nodata-placeholder">
                There is no order to display.
              </div>
            )}
          </div>
          <div>
            <AppPagination
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              rowsTotal={orders.length}
              showAll={true}
            />
          </div>

          {/***** MODAL: REPORT_UNITS **************************************************************************/}
          <Modal
            id="mdl-report-units"
            dimmer={"inverted"}
            open={mdl_report_units}
            onClose={() => set_mdl_report_units(false)}
          >
            <MDL_REPORT_UNITS set_mdl_open={set_mdl_report_units} />
          </Modal>
        </div>
      )}
      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
};

export default Home;
